.account-nav .nav{
    display: block;
    background: #0d89e2;
    border-radius: 5px;
    
    
}
.account-nav .nav .nav-item .nav-link{
    color: #fff;
    text-transform: uppercase;
}
.account-nav .nav .nav-item .nav-link.active{
    background: rgb(255, 255, 255, 0.1);
}
.account{
    margin: 10px;
}
.account-content .tab-content>.tab-pane{
    background: #fff;
    border-radius: 5px;
}
.account-nav .nav .nav-item .nav-link svg{
    padding-right:  20px;
}
.edit-btn, .account-title{
    background: #dddddd;
    font-weight: 400;
    color: #000;
}
.account-content svg{
    color: #0d89e2;
    padding-left: 10px;
}

.transaction-history-container {
    padding: 20px;
    background-color: #f9f9f9; /* adjust the color to match your design */
  }
  
  .transaction-history-container h2 {
    color: #333; /* adjust the color to match your design */
    margin-bottom: 0.5rem;
  }
  
  .transaction-history-container p {
    color: #666; /* adjust the color to match your design */
    margin-bottom: 1rem;
  }
  
  .show-earlier-container {
    display: flex;
    justify-content: center; /* centers the button horizontally */
    margin-bottom: 1rem;
  }
  
  .transaction-list {
    background-color: #fff; /* adjust the color to match your design */
    padding: 1rem;
    /* add more styles for borders, shadows, etc., to match the design */
  }
  .affiliate-program {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #083979; /* or the background color of your choice */
    color: #ffffff;
    padding: 20px;
    border-radius: 5px;
  }
  
  .affiliate-info {
    max-width: 60%;
  }
  
  .affiliate-info h2,
  .affiliate-info p {
    margin-bottom: 10px;
  }
  
  .commission-info {
    font-weight: bold;
  }
  
  .levels {
    display: flex;
    justify-content: space-evenly;
    margin: 20px 0;
  }
  
  .level {
    text-align: center;
  }
  
  .percentage-circle {
    background-color: #083979; /* Adjust to your preferred color */
    border-radius: 50%;
    width: 80px; /* Adjust as necessary */
    height: 80px; /* Adjust as necessary */
    line-height: 80px; /* Adjust so text is centered vertically */
    margin-bottom: 5px;
    border: #fff 3px dashed;
  }
  
  .level-text {
    font-weight: bold;
  }
  
  .affiliate-network {
    text-align: end;
    width: 40%;
  }
  .affiliate-network img{
    width: 75%;
  }

  
  #terms-checkbox {
    margin-right: 10px;
  }
  
  .take-part-button {
    background-color: #083979;
    border: none;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: bold;
    margin: 0 20px;
  }
  

  .bonuses-and-gifts-container {
    background-color: #083979; /* Adjust to the color of your choice */
    color: white;
    padding: 20px;
  }
  
  .bonuses-section h1,
  .gifts-section h2 {
    margin-bottom: 10px;
  }
  
  .bonuses-section p,
  .gifts-section p {
    margin-bottom: 20px;
  }
  
  .promo-code-entry {
    display: flex;
    gap: 10px; /* Adjust the gap as necessary */
    margin-bottom: 20px;
    width: 100px;
  }
  
  .promo-code-entry input {
    flex-grow: 1;
    padding: 10px;
    border: 2px solid #fff; /* Dotted border style is not possible with pure CSS */
    background-color: transparent;
    color: white;
    
  }
  .promo-code-entry input:focus{
    outline: none;
  }
  .promo-code-entry button {
    padding: 10px 20px;
    background-color: #0621b8; /* Adjust to the color of your choice */
    border: none;
    color: white;
    cursor: pointer;
  }
  
  .gifts-section .terms {
    text-decoration: underline; /* Make terms and conditions look clickable */
    cursor: pointer;
  }
  
  .no-gifts {
    text-align: center;
  }
  .query-form-container {
    
    margin: auto;
    padding: 20px;
    background-color: #f9f9f9; /* Light background for the form */
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  }
  
  .query-form-container h2 {
    color: #004d40; /* Dark teal color */
  }
  
  .query-form-container p {
    color: #333; /* Dark grey color */
  }
  
  .query-form-container form {
    display: flex;
    flex-direction: column;
  }
  
  .query-form-container textarea{
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc; /* Light grey border */
    border-radius: 4px;
    height: 150px;
  }
  .query-form-container textarea:focus{
    outline: none;
  }
  
  .query-form-container button {
    padding: 10px 20px;
    background-color: #083979; /* Dark teal background */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .query-form-container button:hover {
    background-color: #003d34; /* Slightly darker teal on hover */
  }
  
  .bet-history {
    
    margin: auto;
    padding: 20px;
    background-color: #f9f9f9; /* Light background for the history panel */
  }
  
  .bet-history h1,
  .bet-history p {
    color: #004d40; /* Dark green color */
  }
  
  .bet-categories {
   
    margin-bottom: 20px;
  }
  
  .category-button {
    background-color: transparent;
    padding: 10px 20px;
    cursor: pointer;
    margin: 0 10px;
    border: none;
    border-radius: 30px;
  }
  
  .category-button.active{
    background-color: rgb(0, 77, 64);
    color: white;
  }
  .category-button:hover {
    background-color: rgb(0, 77, 64, 0.3);
    color: white;
  }
  
  .date-picker {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .date-picker input[type="date"] {
    padding: 10px;
    margin-right: 10px;
    border-radius: 30px;
  }
  
  .actions {
    display: flex;
    justify-content: start;
  
  }
  
  .action-button {
    background-color: #004d40;
    border: none;
    color: white;
    padding: 10px 20px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 30px;
  }
  
  .action-button.special {
    background-color: #026c60; /* Slightly lighter green */
  }
  
  .filters {
    display: flex;
    justify-content: start;
    margin-bottom: 20px;
  }
  
  .filters select {
    padding: 10px 30px;
    margin-right: 10px;
    border-radius: 30px;
  }
  
  .bet-status {
    margin-bottom: 20px;
  }
  
  .sort {
    display: flex;
    justify-content: end;
  }
  
  .sort select {
    padding: 10px;
  }
  
  .bet-shop {
    font-family: 'Arial', sans-serif;
    background-color: #ecf0f1;
    padding: 20px;
    color: #333;
  }
  
  .shop-header h1 {
    color: #2c3e50;
    margin-bottom: 10px;
  }
  
  .shop-header p {
    font-size: 14px;
    margin-bottom: 20px;
  }
  
  .promo-points-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
  }
  
  .your-promo-points {
    font-size: 18px;
    font-weight: bold;
  }
  
  .promo-points-value {
    background-color: #2ecc71;
    border-radius: 20px;
    padding: 5px 15px;
    color: #fff;
    margin-left: 10px;
  }
  
  .promo-request-info {
    font-size: 14px;
  }
  
  .promo-request-button {
    background-color: #2ecc71;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    margin-left: 20px;
  }
  
  .categories-section {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .category-item {
    text-align: center;
    flex-grow: 1;
    padding: 10px;
    margin: 0 5px;
    background-color: #fff;
    border-radius: 10px;
  }
  
  .category-icon {
    display: block;
    width: 60px;
    height: 60px;
    margin: 0 auto 10px;
    background-color: #7f8c8d;
    border-radius: 50%;
  }
  

  
  .bet-options-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .betting-header h2 {
    margin: 20px 0;
    padding: 0;
    display: inline-block;
    font-size: 24px;
    color: #ffffff;
    background-color: #2c3e50;
    border-radius: 5px;
  }
  
  .bet-cards-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  
  .bet-card {
    width: 254px; /* Set the width of the card */
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    margin: 10px;
  }
  
  .card-content {
    position: relative;
  }
  
  .card-top {
    position: relative;
  }
  
  .card-bg {
    width: 100%;
    display: block;
    
  }
  
  .card-value {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 24px;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.5);
    padding: 5px;
    border-radius: 5px;
  }
  
  .card-bottom {
    background-color: #27ae60;
    padding: 10px;
    text-align: center;
  }
  
  .card-points {
    font-size: 20px;
    color: #ffffff;
    margin-bottom: 5px;
  }
  
  .card-type {
    font-size: 18px;
    color: #ffffff;
    font-weight: bold;
  }
  