.mobile-nav-contaniner{
    background: rgba(0, 0, 0, 0.432);
    width: 100%;
    position: fixed;
    z-index: 10;
    display: none;
    
}

.mobile-menu{
    display: none;
}
.mobile-nav-contaniner.open{
    display: flex;
    justify-content: space-between;

}
.mobile-nav-close{
    margin: 20px;
}
.mobile-nav{
    overflow: scroll;
    width: 80%;
    background-color: #1581cf ;
    height: 100vh;
    padding: 10px;
}
.mobile-nav .nav-item{
    background: #ffffff1e;
    margin: 10px;
    list-style: none;
    border-radius: 5px;
    padding: 10px;
}
.mobile-nav .nav-item .nav-link{
    color: #fff;
    font-weight: 500;
}
.mobile-nav .nav-item:hover{
    background: #ffffff80;
}

.nav-header img{
    width: 140px;

}
.nav-header{
    display: flex;
    margin: 20px;
    justify-content: space-between;
}
svg{
    color: #fff;
}
.nav-home-icon {
    background-color: #ffffff67;
    padding: 10px 15px;
    border-radius: 10px;
}