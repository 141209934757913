.login-container {
    
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .login-form {
    width: 400px;
    background: #073642; /* Slightly lighter dark background */
    padding: 20px;
    border-radius: 5px;
    color: white;
  }
  
  .input-group input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: none;
    border-radius: 5px;
  }
  
  .options {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .remember-me input {
    margin-right: 5px;
  }
  
  .login-btn, .google-btn, .xbox-btn {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: none;
    border-radius: 5px;
    background-color: #2aa198; /* Greenish button color */
    color: white;
    cursor: pointer;
  }
  
  .google-btn {
    background-color: #db4437; /* Google button color */
  }
  
  .xbox-btn {
    background-color: #107c10; /* Xbox button color */
  }
  
  .register-link {
    text-align: center;
    margin-top: 10px;
  }
  
  .register-link a {
    color: #2aa198; /* Same as button color */
  }
  
  .registration-container {
    width: 100%;
    padding: 20px;
  }
  
  /* Custom styles for Bootstrap Tabs */
  .nav-link.active {
    background-color: #28a745!important;
    color: white!important;
  }
  
  /* Custom styles for Slick Slider */
  .slick-dots li button:before {
    color: green;
  }
  
  .slick-prev:before, .slick-next:before {
    color: black;
  }

  .signup{
    width: 100%;
    background: #fff;
    margin: 15px;
    border-radius: 8px;
    padding: 100px;
  }
  