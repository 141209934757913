
*,*:before,*:after {
  box-sizing: border-box
}



html {
  overscroll-behavior: contain;
  scrollbar-width: thin;
  touch-action: manipulation;
  width: 100%
}

body {
  overflow-anchor: none;
  margin: 0;
  width: 100%;
  overflow-x: hidden;
  font-size: .875rem;
  line-height: 1.6;
  background: #1581cf !important;
  overscroll-behavior: contain;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: thin;
  scrollbar-color: #1581cf transparent
}

body #root {
  overflow-x: hidden
}


a {
  text-decoration: none
}

a:active {
  
  text-decoration: none
}

a:active:hover {
  cursor: pointer
}

hr {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #000;
  margin: 1em 0
}


#root {
  overflow-x: hidden
}

#root>.loading {
  height: 90vh
}

.grecaptcha-badge {
  display: none!important
}

.flex {
  display: flex
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center
}

.flex-column {
  display: flex;
  flex-direction: column
}

.flex-middle {
  display: flex;
  align-items: center
}

.cl-primary {
  color: var(--primary-color)
}

.link {
  cursor: pointer
}

.link:hover {
  text-decoration: underline
}

.hover:hover {
  color: var(--primary-color)
}

.hover:hover .icon {
  fill: var(--primary-color)
}


.scroll-behavior {
  scroll-behavior: smooth
}

.hidden-scroll-y {
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.hidden-scroll-y::-webkit-scrollbar {
  width: 0!important
}


@media screen and (min-width: 621px) {
  body {
      scrollbar-width:none
  }

  body::-webkit-scrollbar {
      margin-right: 5px;
      width: 0
  }

  ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background-color: transparent;
      border-radius: var(--border-radius)
  }

  ::-webkit-scrollbar-thumb {
      background-color: #fff;
      transition: all .5s;
      border-radius: var(--border-radius)
  }

  ::-webkit-scrollbar-button {
      display: none
  }

  ::-webkit-scrollbar-button:vertical:end:increment {
      display: block;
      height: 20px;
      background-color: transparent
  }


  ::-webkit-scrollbar-button:vertical:start:increment {
      display: block;
      height: 20px;
      background-color: transparent
  }

  * {
      scrollbar-color: #0b7dda transparent;
      scrollbar-width: thin;
      scrollbar-width: none
  }
}

@media screen and (max-width: 621px) {
  .page-max-width-wrap {
      width:100%;
      max-width: 100%;
      padding: 0
  }
}

@keyframes breathe {
  0% {
      opacity: 0
  }

  50% {
      opacity: 1
  }

  to {
      opacity: 0
  }
}

@keyframes rotate {
  0% {
      transform: rotate(0)
  }

  to {
      transform: rotate(360deg)
  }
}
.casino-list img{
  border-radius: 8px;
  width: 100%;
}
.casino .search-bar{
  width: 100%;
  height: 60px;
  background: #0875ce;
  line-height: 60px;

}
.casino .search-bar input{
  width: 300px;
  background: rgb(104, 161, 207);
  height: 40px;
  border: none;
  color: #000;
  border-radius: 5px;
  margin: 0px 20px;
  padding: 0 20px;
  
}
 input:focus{
  outline: none;
}
.casino-list-hover{
  display: none;
}
.casino-list:hover .casino-list-hover{
  background: rgb(15, 15, 15, 0.7);
  width: 100%;
  height: 77%;
  position: relative;
  top: -77%;
  border-radius: 8px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.casino-list-hover button{
  border: none;
  background: #0875ce;
  width: 80px;
  height: 30px;
  color: #fff;
  border-radius: 2px;
  margin: 10px;
}
.casino-list-hover p{
      color: #fff;
      font-size: 20px;
      font-weight: bold;
      
}

/* Add this CSS to your stylesheet or in a <style> tag */
.loading-spinner {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: auto;
  display: block;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  width: 100%; /* Full width */
}
