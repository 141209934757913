.poker-banner{
    background: url('https://v2l.traincdn.com/genfiles/cms/1/desktop/slots/products/323/bannerpoker323-new.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 600px;
    margin: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.poker-action{
    color: #fff;
    background: rgb(0, 0, 0, .9);
    padding: 10px 40px;
    border: #fff solid 1px;
    margin-right: 180px;
    margin-top: 100px;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: 600;
    
}

.poker-action span{
    padding-left: 10px;
}