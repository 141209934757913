.promo-card {
    width: 100%;
    height: 616px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    position: relative;
  }
  
  .promo-header {
     /* Replace with the actual image URL */
    background-size: cover;
    background-color: #fff;
    background-position: center;
    height: 70%; /* Adjust based on actual header size in the image */
    position: relative;
  }
  

  .promo-body {
    background-color: #ffffff; /* Adjust the color to match the design */
    padding: 20px;
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  
  .promo-title {
    color: #000000; /* Adjust the color to match the design */
    font-size: 24px; /* Adjust the size to match the design */
    margin-bottom: 16px;
  }
  
  .promo-description {
    color: #555555; /* Adjust the color to match the design */
    margin-bottom: 24px;
  }
  
  .promo-button {
    background-color: #00B233; /* Adjust the color to match the button in the design */
    color: #ffffff;
    border: none;
    padding: 15px 32px;
    font-size: 18px; /* Adjust the size to match the design */
    cursor: pointer;
    border-radius: 5px; /* Adjust the radius to match the design */
    transition: background-color 0.3s ease;
  }
  
  .promo-button:hover {
    background-color: #009A2E; /* Darker shade for hover effect */
  }
  