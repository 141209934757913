.footer-container {
    display: flex;
    flex-direction: column;
    background-color: #fff; /* Replace with the correct background color */
    padding: 20px;
    font-family: Arial, sans-serif; /* Replace with the correct font-family */
    border-radius: 0px 0px 5px 5px;
    margin: 7px 15px;
  }
  
  .footer-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc; /* Replace with correct color */
  }
  


  /* Add additional specific styles for the logos and certification images */
  .payment-options {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .age-notice {
    border: red solid 2px; /* Replace with the correct color */
    color: red;
    padding: 10px 10px;
    border-radius: 50%;
    font-weight: bold;
    margin-right: 30px;
  }
  
  .footer-bottom {
    margin-top: 20px;
    text-align: center;
    color: #666; /* Replace with the correct text color */
    
  }
  
  /* Responsive styles if necessary */
  @media (max-width: 768px) {
    .footer-top {
      flex-direction: column;
    }
  }
  