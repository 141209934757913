@media screen and (max-width: 1440px) {
   .media-1400{
    display: none;
   }
   .media-1400-s{
    display: block;
  }
  
  
  }
  @media screen and (min-width: 1024px) and (max-width: 1270px) {
        .media-1024{
            display: none;
        }
        .media-1024-s{
            display: block !important;
          }
        .nav-link.media-1440-s{
            display: none !important;
        }
        .nav-logo img {
          width: 140px;
         
      }
      .nav-menu{
        width: 70%;
      }
      .poker-action{
        margin-right: 30px;
      }
      .nav-action{
        width: 15%;
      }
        
        
  }
  @media screen and (min-width: 320px) and (max-width: 1024px) {
    .nav-footer{
        flex-direction: column;
       }
       .account-nav-link{
        display: none;
       }
       .poker-action{
        margin: 0;
       }
       .btn-ui{padding: 7px 15px;}
       .poker-banner{
        justify-content: center;
       }
       .topmenu .nav-action{
        display: none;
       }
       .topmenu .nav-menu{
        display: none;
       }
       .nav-logo{
        width: 60%;
       }
      .mobile-menu{
        display: block !important;
      } 
       .account-nav{
        position: fixed;
        bottom: 0;
       }
       .account-nav .nav{
        display: flex;
        flex-wrap: inherit;
        text-align: center;
        overflow: scroll;
       }
    
   
  }
  