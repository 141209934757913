
.navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    color: white;
    border: rgba(75, 73, 73, 0.7) solid 1px;
  }
  
  .navbar-title {
    font-size: 24px;
    margin: 20px;
    color: #fff;
  }
  
  .navbar-menu {
    list-style: none;
    display: flex;
    gap: 10px; /* Adjust the gap to match your design */
    margin: 0;
    padding: 0;
  }
  
  .navbar-item {
    background-color: #26a69a; /* Light teal color */
    padding: 10px 20px;
    border-radius: 20px 20px 0 0; /* Adjust to get the desired curvature */
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .navbar-item:hover {
    background-color: #00796b; /* A slightly darker teal color for hover effect */
  }
  
  .nav-footer {
    display: flex;
    justify-content: space-around;
    background-color: #fff; /* Replace with the color of your footer background */
    padding: 40px;
    color: #000;
    margin: 0px 15px;
    border-radius: 5px 5px 0px 0px;
  }
  
  .footer-section {
    display: flex;
    flex-direction: column;
  }
  
  .footer-heading {
    border-bottom: 2px solid #0b7dda; /* Replace with the color of your heading underline */
    padding-bottom: 5px;
    margin-bottom: 20px;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section ul li {
    padding: 5px 0;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .footer-section ul li:hover {
    color: #26a69a; /* Replace with the color you want on hover */
  }
  .about-us h1{
    color: #fff;
  }
  .about-content{
    background: #fff;
    padding: 10px;
    border-radius: 8px;
    text-align: start;
  }
  .about-content h3{
    color: #3d3c3c;
    margin: 10px 0;
  }
  .about-content img{
    width: 100%;
  }